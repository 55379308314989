import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../components/layout"
import H1Category from "../../../components/h1Category"
import ContentPage from "../../../layouts/contentPage"
import ProductBox from "../../../components/productBox"
import ProductBoxCta from "../../../components/productBox/productBoxCta"
import ProducstList from "../../../components/productsList"
import Seo from "../../../components/seo"

import ArrowRight from "../../../images/arrow-right.svg"
import ProductBanner from "../../../components/productBanner"



const Category = () => (
    <Layout lang={'EN'}>
      <Seo title="Oxygenetic Energyze" lang={'EN'} />
      <ContentPage>
            <H1Category color="lime">Energyze</H1Category>
            <ProducstList>

                <ProductBox data-color="lime">
                    <Link to="/en/products/energizing-serum">
                        <StaticImage
                        src="../../images/media/products/energyze/category/energetyzujace-serum.png"
                        loading="eager"
                        width={446}
                        quality={95}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="Energizing face day serum"
                        />
                        <ProductBoxCta className="cta" data-color="lime">
                            <div>
                                <h3>Energizing face day serum</h3>
                                <p>SPF 15</p>
                            </div>
                            <ArrowRight/>
                        </ProductBoxCta>
                    </Link>
                </ProductBox>

                <ProductBox data-color="lime">
                    <Link to="/en/products/oxygenating-sorbet">
                        <StaticImage
                        src="../../images/media/products/energyze/category/nawilzajacy-sorbet.png"
                        loading="eager"
                        width={446}
                        quality={95}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="Hydrating and oxygenating face wash sorbet"
                        />
                        <ProductBoxCta className="cta" data-color="lime">
                            <div>
                                <h3>Hydrating and oxygenating </h3>
                                <p>face wash sorbet</p>
                            </div>
                            <ArrowRight/>
                        </ProductBoxCta>
                    </Link>
                </ProductBox>

                <ProductBox data-color="lime">
                    <Link to="/en/products/regenerating-serum">
                        <StaticImage
                        src="../../images/media/products/energyze/category/regeneracyjne-serum.png"
                        loading="eager"
                        width={446}
                        quality={95}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="Regenerating night-time serum"
                        />
                        <ProductBoxCta className="cta" data-color="lime">
                            <div>
                                <h3>Regenerating night-time</h3>
                                <p>face serum</p>
                            </div>
                            <ArrowRight/>
                        </ProductBoxCta>
                    </Link>
                </ProductBox>

                <ProductBox data-color="lime">
                    <Link to="/en/products/normalizing-serum">
                        <StaticImage
                        src="../../images/media/products/energyze/category/nawilzajace-serum.png"
                        loading="lazy"
                        width={446}
                        quality={95}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="Normalizing repair serum"
                        />
                        <ProductBoxCta className="cta" data-color="lime">
                            <div>
                                <h3>Normalizing repair</h3>
                                <p>serum</p>
                            </div>
                            <ArrowRight/>
                        </ProductBoxCta>
                    </Link>
                </ProductBox>

                <ProductBox data-color="lime">
                    <Link to="/en/products/revitalizing-serum">
                        <StaticImage
                        src="../../images/media/products/energyze/category/rewitalizujace-serum.png"
                        loading="lazy"
                        width={446}
                        quality={95}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="Revitalizing eye serum"
                        />
                        <ProductBoxCta className="cta" data-color="lime">
                            <div>
                                <h3>Revitalizing eye </h3>
                                <p>serum</p>
                            </div>
                            <ArrowRight/>
                        </ProductBoxCta>
                    </Link>
                </ProductBox>

                <ProductBanner productLine="energyze"/>


          </ProducstList>
      </ContentPage>
    </Layout>
)

export default Category
